import React, { lazy, Suspense } from 'react';

import { getEnvMap } from './envVarMgr.js';
import dataUtil from '../server/dataUtil.js';
import logit from '../server/logit.js';

export { siteData };
const siteData = (parms) => {
  let { type, pagePath = '/', getEnvVar = null } = parms;
  let seoPageData = {};

  //console.log('siteData: dump params', parms);
  // usage:
  // Get routes - THIS HAD PROBLEMS TO CONSOLIDATE and hence it's still in its own file: src/routeList
  //     MAY WANT TO TRY GENERATING THE LIST inside src/routeList from this file
  //     const routes = siteData({ type: 'routeList' });
  //
  // get navigation options (headers/footers)
  //     return siteData({ type: 'headerOptions' });
  //     return siteData({ type: 'footerOptions' });
  //
  // get seoData   
  //     seoPageData: {caller, frontmatter = {}, staticDataLoadResp = null, slug = null }
  //
  // get serverData TODO
  //     

  //
  // Unable to support routeList with imports for '*.mdx' files as it complains about unable to load '.mdx' files.
  // This happens loc:devnode and loc:devvite.
  // So, we keep it in a separate file. src/routeList.js
  /*
  const routeList = [
    { path: "/",                    import: () => import("../pages/Home") , module: "../pages/Home"},
    ....
  ]
  */
  if (!getEnvVar) {
   getEnvVar = getEnvMap();
  }

  let headerOptions;
  let footerOptions;
  if (type == 'headerOptions' || type == 'footerOptions') {
    // draft: true|false
    // type: page|mdx|ext|     
    // use:  hdr|bgr|dd|ftr
    // auth: y|n
    // path can also be link to a section within a page: path: '/somepage/#section
    const signupPreset = { "Application": getEnvVar.APP_NAME.toUpperCase(), "Plan": getEnvVar.EMERT_INVITE_PLAN, "InviteCode": getEnvVar.EMERT_INVITE_OFFER };

    const signupPresetStr = JSON.stringify(signupPreset);
    const signupUrl = `${getEnvVar.signupPartUrl}&preset="${signupPresetStr.replace(/"/g, '\\"')}"`;

    const headerPaths = [
      //{ display: 'Home',             path: '/',                  draft: false, type: 'page', use: ['hdr','bgr'],      auth: ['y','n'] },
      //{ display: 'Select',             path: '/select',            draft: true,  type: 'page', use: ['hdr','bgr'],      auth: ['y','n'] },
      { display: 'Recalls',             path: '/recall',           draft: false,  type: 'page', use: ['hdr','bgr'],      auth: ['y','n'] },
      { display: 'Recent',             path: '/recent',            draft: false, type: 'page', use: ['hdr','bgr'],      auth: ['y','n'] },
      { display: 'Services',           path: '/services',          draft: false, type: 'page', use: ['hdr','bgr'],      auth: ['y','n'] },
      { display: 'Get Started',        path: '/getstarted',        draft: false, type: 'page', use: ['hdr','bgr'],      auth: ['y','n'] },
      { display: 'Pricing',            path: '/pricing',           draft: false, type: 'page', use: ['hdr','bgr'],      auth: ['y','n'] },

      { display: 'My Recalls',         path: '/myrecalls',            draft: false, type: 'page', use: ['hdr','bgr'],      auth: ['y','n'] },

      { display: 'Partners',           path: '/partners',          draft: true, type: 'page', use: ['hdr','bgr'],      auth: ['y','n'] },
      { display: 'Portfolio',          path: '/portfolio',         draft: true, type: 'page', use: ['hdr','bgr'],      auth: ['y','n'] },

      { display: 'Blog',               path: '/blog',              draft: true,  type: 'page', use: ['hdr','bgr'],      auth: ['y','n'] },

      { display: 'divider',              path: '',                              draft: false, type: 'page', use: ['bgr'],       auth: ['y','n'] },

      { display: 'My Account',              path: '/myaccount',                      draft: false, type: 'page', use: ['hdr','bgr'],  auth: ['y','n'] },
    ];

    const footerPaths = [
      { display: 'About',              path: '/about',             draft: false, type: 'mdx', use: ['ftr','bgr'],      auth: ['y', 'n'] },
      { display: 'Disclaimers',        path: '/disclaimers',       draft: false, type: 'mdx', use: ['ftr','bgr'],      auth: ['y', 'n'] },
      { display: 'Credits',            path: '/credits',           draft: false, type: 'mdx', use: ['ftr','bgr'],      auth: ['y', 'n'] },
      { display: 'FAQ',                path: '/faq',               draft: false, type: 'mdx', use: ['ftr','bgr'],      auth: ['y', 'n'] },
      { display: 'Learn',              path: '/learn',             draft: false, type: 'mdx', use: ['ftr','bgr'],      auth: ['y', 'n'] },
      { display: 'How To',             path: '/howto',             draft: false, type: 'mdx', use: ['ftr','bgr'],      auth: ['y', 'n'] },
      { display: 'Privacy',            path: '/privacy',           draft: false, type: 'mdx', use: ['ftr','bgr'],      auth: ['y', 'n'] },
      { display: 'Terms & Conditions', path: '/termsandconditions', draft: false, type: 'mdx', use: ['ftr','bgr'],      auth: ['y', 'n'] },
      { display: 'Contact Us',         path:getEnvVar.contactUsUrl,draft: false, type: 'ext', use: ['ftr','bgr'],      auth: ['y', 'n'] },
    ];

    headerOptions = {
      appLogoFilePath: getEnvVar.APP_LOGO_FILE_PATH,
      logoWidth: 48,   // manually set
      logoHeight: 48,   // manually set
      logoText: 'RecallsMe', //'RECALLS.ME', //getEnvVar.LOGO_TEXT,   // logoText is optional. If supplied it'll be listed
      logoTextColor: '#ff0000',
      logoTextType: 'boxed',   // 'plain' | 'rainbow' | 'boxed'
      logoRainbowColor: '#f90404',
      logoFont: 'Abril Fatface',
      appLogoHref: '/',
      headerPaths: headerPaths,
      activeStyle: '2px solid red',
      inactiveStyle: '2px solid transparent',
      navBarHeightOffset: '56px',
    };

    footerOptions = {
      footerPaths: footerPaths,
      hoverTextColor: '#ff0000',
    }

    /* Future usage for page context menu - social share - will it work in iPad?

       const menuPaths = [
       'Context Menu', 'A', 'B'
       ];

       menuOptions = {
       menuPaths: menuPaths,
       }
     */
  }

  //
  // routes needing data
  //
  // note: All listed routes' component requires code change - access allSrvrData
  if (type == 'routesNeedingData') {
    return ['/', '/recent', '/blog'];
  }

  //
  // routes needing Adverts
  //
  if (type == 'routesAdSpecs') {
    const Adverts = lazy(() => import('../area/Adverts'));
    return [
      { page: '/recall',
	ad: Adverts,
	options: { left: false, right: true, bottom: false }
      },
      { page: '/recent',
	ad: Adverts,
	options: { left: false, right: true, bottom: false }
      },
    ];
  }
  
  if (type == 'seoData' && pagePath) {
    let title, description, type, path, keywords;
    let pic;
    keywords = ['custom', 'software', 'react'];

    let emert = null;
    let emertsLength = 0;
    let url = null;
    /*
    if (staticDataLoadResp) {
      if (staticDataLoadResp.data && staticDataLoadResp.data.emert) {
	emert = staticDataLoadResp.data.emert;
	emertsLength = 1;
	url = staticDataLoadResp.data.url;
      }
      else
	// This needs to be used for SEO
	if (staticDataLoadResp.data && staticDataLoadResp.data.emerts) {
	  emert = staticDataLoadResp.data && staticDataLoadResp.data.emerts[0] || null;
	  emertsLength = staticDataLoadResp.data && staticDataLoadResp.data.emerts.length || 0;
	}
    }
    */

    /*
    // Strip out '/pages' from '/pages/xxx'
    // doesn't work for the error page: '/renderer/_error'
    const callerWOPages = caller.substr(6);
    const pos = callerWOPages.lastIndexOf('/')
    let callerWOIndex = callerWOPages;
    if (pos !== -1) {
      callerWOIndex = callerWOPages.substr(0, pos);
    }
    */

    //console.log('seoSitePageData pagePath ', pagePath);
    //console.log('seoSitePageData pagePath', pagePath);
    //console.log('seoSitePageData emert', emert);

    /* A Template for seoPageData ....
       type: 'WebPage',                // 'BlogIndexPage', 'BlogPage', 'MDXPage', 'WebPage', 'WebIndexPage', 'AppPage', 'ErrorPage'
       personSeoCode: 'principal',
       
       dateModified: Date(),           // date Specs - Date is req'd for blog SEO
       datePublished: Date(),
       //           '123456789012345678901234567890123456789012345678901234567890123456789012345678901234567890123456789012345678901234567890123456789 = 130
       description: 'Some description .... ',   // description:  used for <meta name="description" content="text..." >   - createSeoCotnent.js truncates description to 150 chars
       keywords: ['consumer', 'institution', 'supplier'],   // Keywords Specs - Up to five keywords or keyword phrases for each page
       path: pagePath,
       //pic: '',
       //slug: 'blog',
       slogan: '',
       //     '12345678901234567890123456789012345678901234567890 = 50
       title: 'SEO: seoInfo is not setup for this page',     //    - createSeoCotnent.js truncates title to 50 chars
       isCanonical: true, | false,   // Indicates if the page is the original source. /pages/select/xx & /pages/recent/xx are marked false

       // Compact Version
       seoPageData = {
       type: 'WebPage',
       personSeoCode: 'principal',
       dateModified: '',
       datePublished: '',
       description: '',
       keywords: ['', '', '', '', ''],
       path: pagePath,
       slogan: '',
       title: '',
       isCanonical: true,
       };

     */

    switch (pagePath) {
    case '/index.html':
    case '/':
      seoPageData = {
	type: 'WebIndexPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'The one and only web site offering a complete searchable list of all government announced recalls and safety alerts',
	keywords: ['track', 'list', 'search'],
	path: pagePath,
	slogan: '',
	title: 'Search, list & view all recalls in one place',
      };
      break;
    case '/recall':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Complete repository of every recall ever announced along with full details, pictures and cause for recall.',
	keywords: ['', '', '', '', ''],
	path: pagePath,
	slogan: '',
	title: 'Complete repository of all recalled products',
      };
      break;
    case '/recall/@id':                    // Recall Details for all statically generated page
      title = 'Recalls Details';
      description = 'Recalls Description';
      type = 'Recalls';
      path = '/recall';
      if (pagePath !== '/recall') {
	path = url;   ///recall/102096-best-buy-recalls-insignia-air-fryers-and-air-fryer-ovens-due-to-fire-and-burn-h/
      }
      keywords = ['food', 'drugs', 'vin', 'meat', 'products'];

      if (emert) {
	pic = emert.jsonProp.Images[0]?.img.src || '/images/productImageComingSoon.webp';

	const mfg =
	  (emert.jsonProp.Manufacturers &&
	    emert.jsonProp.Manufacturers[0] &&
	    emert.jsonProp.Manufacturers[0]['Name']) ||
	    '';
	const hazards =
	  (emert.jsonProp.Hazards &&
	    emert.jsonProp.Hazards[0] &&
	    emert.jsonProp.Hazards[0]['Name']) ||
	    '';
	//type = ` ${type} ${recallTypeToText(emert.type)}`;

	// title
	title = emert.jsonProp.Title || `${mfg ? mfg : hazards}`;
	title = title.substr(0, 50);

	// desc
	const desc = emert.jsonProp.Description;
	description = `Recalls · ${desc}`;

	// keywords
	keywords = ['recalls'];
	if (mfg) keywords.push(mfg);
	if (hazards) {
	  const list = hazards.split(' ');
	  if (list.length > 12) list.forEach(word => word.length > 9 && keywords.push(word));
	  else list.forEach(word => word.length > 5 && keywords.push(word));
	}
      }
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: description,
	keywords: keywords,
	path: path,
	pic: pic,
	slogan: '',
	title: title,
      };
      break;
    case '/recent':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Keep in the know and be informed about what is recently recalled by each of the government agencies.',
	keywords: ['', '', '', '', ''],
	path: pagePath,
	slogan: '',
	title: 'Five most recent recalls from each federal agency',
      };
      break;
    case '/services':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Helping consumers, institutions and suppliers to be informed, aware and safe',
	keywords: ['', '', '', '', ''],
	path: pagePath,
	slogan: '',
	title: 'Helping Everyone To Be Informed, Aware And Safe',
      };
      break;
    case '/getstarted':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Are you worried about unknowingly using a recalled products? Start using RecallsMe to be informed, aware and safe. Sign Up Now!',
	keywords: ['consumer', 'institution', 'supplier'],
	path: pagePath,
	slogan: '',
	title: 'Recall Safety Alerts. Get Started To Be Informed',
      };
      break;
    case '/myrecalls':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Personal recalls dashboard to help you easily search and view details on any recall from our complete repository of recalls',
	keywords: ['recent', 'current', 'latest'],
	path: pagePath,
	slogan: '',
	title: 'Dashboard: Search/List/View all types of recalls',
      };
      break;
    case '/myaccount':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Configure and manage your account, plans and personal preferences',
	keywords: ['signin', 'signout', 'plans', 'preferences'],
	path: pagePath,
	slogan: '',
	title: 'My Account: Manage access, plans and preferences',
      };
      break;
    case '/claim':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Unclaimed Property. Money that is owed to you is free and you can claim it from your state.',
	keywords: ['', '', '', '', ''],
	path: pagePath,
	slogan: '',
	title: 'Unclaimed Property - Claim that is rightly yours',
      };
      break;
    case '/about':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Consolidated list of all recalls, timely notifications, search and view analytics. Keep informed, aware and safe!',
	keywords: ['recalls', 'consumer products', 'safety', 'alerts', 'free'],
	path: pagePath,
	slogan: '',
	title: 'How safe are the products we consume?',
      };
      break;
    case '/disclaimers':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'RecallsMe is not endorsed by nor affiliated with any federal, state or local government agencies.',
	keywords: ['', '', '', '', ''],
	path: pagePath,
	slogan: '',
	title: 'Best at delivering accurate & timely recalls data',
      };
      break;
    case '/credits':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Credits: Data by local, state and federal agencies. Open Source Software libraries and products help build RecallsMe',
	keywords: ['', '', '', '', ''],
	path: pagePath,
	slogan: '',
	title: 'Express gratitude to OSS and government agencies',
      };
      break;
    case '/faq':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Learn: What/Who/Why/How/When/Where of recalls and how RecallsMe helps you stay informed, aware and safe from recalled products',
	keywords: ['list', 'search', 'faq'],
	path: pagePath,
	slogan: '',
	title: 'FAQ: Product Recalls, Withdrawals & Safety Alerts',
      };
      break;
    case '/learn':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Learn: What is a recall, what triggers, where to find all recalls, who initiates, what should I do, what is recalled, etc., ',
	keywords: ['food', 'drugs', 'vin'],
	path: pagePath,
	slogan: '',
	title: 'Learn about products, vehicles, food & all recalls',
      };
      break;
    case '/howto':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Learn the services offered by RecallsMe to stay informed, aware and safe from all type of product recalls',
	keywords: ['', '', '', '', ''],
	path: pagePath,
	slogan: '',
	title: 'How to be informed and aware about all recalls',
      };
      break;
    case '/HowToList':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Learn the services offered by RecallsMe to stay informed, aware and safe  from all type of product recalls',
	keywords: ['food', 'drugs', 'vin'],
	path: pagePath,
	slogan: '',
	title: 'How to list & filter recalls alerts',
      };
      break;
    case '/HowToPwa':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Setup RecallsMe web application as a Progressive Web Application(PWA). No donwloads and automatic updates. Simple and secure',
	keywords: ['', '', '', '', ''],
	path: pagePath,
	slogan: '',
	title: 'How to setup a web app as PWA',
      };
      break;
    case '/HowToRegProd':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Learn how to register any of your everyday consumption items for a potential recall checks and timely notifications',
	keywords: ['food', 'drugs', 'vin'],
	path: pagePath,
	slogan: '',
	title: 'How to register and check product recalls',
      };
      break;
    case '/HowToRegVehi':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Learn how to register your vehicles for a potential recall checks and timely alert notifications',
	keywords: ['food', 'drugs', 'vin'],
	path: pagePath,
	slogan: '',
	title: 'How to register and check vehicle recalls',
      };
      break;
    case '/HowToSearch':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Learn to search for any recall in our comprehensive listing from all government agencies. The only source you\'ll ever need',
	keywords: ['food', 'drugs', 'vin'],
	path: pagePath,
	slogan: '',
	title: 'How to search for any product recalls',
      };
      break;
    case '/HowToSelCat':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Learn how to filter what recall notifications you receive. You can choose one or more categories to be informed, aware and safe',
	keywords: ['food', 'drugs', 'vin'],
	path: pagePath,
	slogan: '',
	title: 'How to specify what recalls alert to receive',
      };
      break;
    case '/HowToSetDel':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Learn how to specify alert delivery options for recall notifications. You can choose: Web push, SMS, WhatsApp or Email alerts',
	keywords: ['food', 'drugs', 'vin'],
	path: pagePath,
	slogan: '',
	title: 'How to specify recalls alert delivery options',
      };
      break;
    case '/privacy':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Website privacy policy',
	keywords: ['', '', '', '', ''],
	path: pagePath,
	slogan: '',
	title: 'Website privacy policy',
      };
      break;
    case '/termsandconditions':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Website terms and conditions policy',
	keywords: ['consumer', 'institution', 'supplier'],
	path: pagePath,
	slogan: '',
	title: 'Website terms and conditions policy',
      };
      break;

    case '/blog':
      // This seo is for the blog's main landing page   (not for the individual blogs)
      seoPageData = {
	type: 'BlogIndexPage',
	personSeoCode: 'principal',
	dateModified: Date(),
	datePublished: Date(),
	description: 'All posts written by RecallsMe staff addressing market recalls for drugs, food, vehicles and other consumer products.',
	keywords: ['products', 'food', 'meat', 'vehicles', 'drugs', 'veterinary'],
	path: pagePath,
	slogan: '',
	title: 'Product recalls: How safe are you? Blog',
      };
      break;
    case '/blog/?id':
      seoPageData = {
	type: 'BlogPage',
	personSeoCode: 'principal',
	dateModified: Date(),
	datePublished: Date(),
	description: 'All posts written by RecallsMe staff addressing market recalls for drugs, food, vehicles and other consumer products.',
	keywords: ['products', 'food', 'meat', 'vehicles', 'drugs', 'veterinary'],
	path: pagePath,
	pic: '',   //frontmatter.pic,   // '/images/mdxpages/chef-boyardee.jpg',
	slogan: '',
	title: 'Products recalls: How safe are you? Blog',
      };
      break;
      /* Following are not in PROD
	 /sampleMdx01
	 /sampleMdx02
	 /sampleMdx03
	 /sampleMdx06
	 /sampleMdxSyntax
       */

    case '/fourofour':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	//           '1234567890123456789012345678901234567890123456789012345678901234567890123456789012345678901234567890123456789012345678901234567890 = 130
	description: 'Sorry. The page you are looking for is not found. It may have moved! Search the RecallsMe web site to find your recalls.',
	keywords: ['consumer', 'institution', 'supplier'],
	path: pagePath,
	slogan: '',
	//     '12345678901234567890123456789012345678901234567890' = 50
	title: '404 - Page not found.',
      };
      break;
    case '/pricing':
      seoPageData = {
	type: 'WebPage', // 'BlogIndexPage', 'BlogPage', 'MDXPage', 'WebPage', 'WebIndexPage', 'AppPage'
	personSeoCode: '',
	dateModified: '',
	datePublished: '',
	description: 'Pricing details',
	keywords: keywords,
	path: pagePath,
	slogan: '',
	title: 'Plans & Pricing',
      };
      break;
    default:
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description:
	  'This page has no description',
	keywords: ['consumer', 'institution', 'supplier'],
	path: pagePath,
	slogan: '',
	title: 'This page has no title',
      };
      break;

  /*
    case '/recent/@id':                    // Recall Details for recent selective recalls
      title = 'Recalls Details';
      description = 'Recalls Description';
      type = 'Recalls';
      path = '/recent';
      if (pagePath !== '/recent') {
	path = url;  // path = /recent/102096-best-buy-recalls-insignia-air-fryers-and-air-fryer-ovens-due-to-fire-and-burn-h/
      }
      keywords = ['food', 'drugs', 'vin', 'meat', 'products'];

      if (emert) {
	pic = emert.jsonProp.Images[0]?.img.src || '/images/productImageComingSoon.webp';

	const mfg =
	  (emert.jsonProp.Manufacturers &&
	    emert.jsonProp.Manufacturers[0] &&
	    emert.jsonProp.Manufacturers[0]['Name']) ||
	    '';
	const hazards =
	  (emert.jsonProp.Hazards &&
	    emert.jsonProp.Hazards[0] &&
	    emert.jsonProp.Hazards[0]['Name']) ||
	    '';
	//type = ` ${type} ${recallTypeToText(emert.type)}`;

	// title
	title = emert.jsonProp.Title || `${mfg ? mfg : hazards}`;
	title = title.substr(0, 50);

	// desc
	const desc = emert.jsonProp.Description;
	description = `Recall · ${desc}`;

	// keywords
	keywords = ['recalls'];
	if (mfg) keywords.push(mfg);
	if (hazards) {
	  const list = hazards.split(' ');
	  if (list.length > 12) list.forEach(word => word.length > 9 && keywords.push(word));
	  else list.forEach(word => word.length > 5 && keywords.push(word));
	}
      }
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: description,
	keywords: keywords,
	path: path,
	pic: pic,
	slogan: '',
	title: title,
      };
      break;


    case '/select/index':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Review our editor\'s top selections of recalls which are interesting, costly, amusing, noteworthy, deadly, reccuring, etc.,',
	keywords: ['', '', '', '', ''],
	path: pagePath,
	slogan: '',
	title: 'Editor\'s selection of interesting recalls',
      };
      break;
    case '/select/@id':                    // Recall Details for recent selective recalls
      title = 'Recalls Details';
      description = 'Recalls Description';
      type = 'Recalls';
      path = '/select';
      if (pagePath !== '/select') {
	path = url;  // path = /select/102096-best-buy-recalls-insignia-air-fryers-and-air-fryer-ovens-due-to-fire-and-burn-h/
      }
      keywords = ['food', 'drugs', 'vin', 'meat', 'products'];

      if (emert) {
	pic = emert.jsonProp.Images[0]?.img.src || '/images/productImageComingSoon.webp';

	const mfg =
	  (emert.jsonProp.Manufacturers &&
	    emert.jsonProp.Manufacturers[0] &&
	    emert.jsonProp.Manufacturers[0]['Name']) ||
	    '';
	const hazards =
	  (emert.jsonProp.Hazards &&
	    emert.jsonProp.Hazards[0] &&
	    emert.jsonProp.Hazards[0]['Name']) ||
	    '';
	//type = ` ${type} ${recallTypeToText(emert.type)}`;

	// title
	title = emert.jsonProp.Title || `${mfg ? mfg : hazards}`;
	title = title.substr(0, 50);

	// desc
	const desc = emert.jsonProp.Description;
	description = `Recall · ${desc}`;

	// keywords
	keywords = ['recalls'];
	if (mfg) keywords.push(mfg);
	if (hazards) {
	  const list = hazards.split(' ');
	  if (list.length > 12) list.forEach(word => word.length > 9 && keywords.push(word));
	  else list.forEach(word => word.length > 5 && keywords.push(word));
	}
      }
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: description,
	keywords: keywords,
	path: path,
	pic: pic,
	slogan: '',
	title: title,
      };
      break;
  */

    } // switch

    // Note: /recall/@id is consider to be isCanonical = true;
    if (pagePath !== '/select/@id' && pagePath !== '/recent/@id') {
      seoPageData['isCanonical'] = true;
    }
    else
      seoPageData['isCanonical'] = false;


  }









  
  
  //if (type == 'routeList') {
  //  return routeList;
  //}
  //else
  if (type == 'headerOptions') {
    return headerOptions;
  }
  else
    if (type == 'footerOptions') {
      return footerOptions;
    }
  else
    if (type == 'seoData') {
      return seoPageData;
    }


};






//        - No need to include 'RecallsMe' in the title.
//        - 'createSeoContent.js' adds suffix from seoOrgData.title (which is ' · RecallsMe').
//        <meta name="recalls" content="The one and only web site offering a complete searchable list of all government announced recalls and safety alerts" />
//        - THIS IS OUR OWN to be removed in future
//        <meta env="development" name="adsense" data-ad-client="" />
//        - THIS IS OUR OWN and only appears in dev env. In prod it'll be replaced with google adsense credentials
//        <meta name="description" content="The one and only web site offering a .... safety //   alerts"/>
//        - unique for each page, 150-160 chars, cta
//        - LIMIT TO 130 Chars (Can be 100 - 320 chars; Search engin may truncate after 155 chars / 130 chars for mobile
//        - 'createSeoContent.js' uses org's description if one is not supplied in the seoPageData.
//        - Insight into what your page is about. Includes the page’s main keywords.
//        - Use your brand name in title and Description.
//        - truthfully describe the page content.
//        - advertise the page and improve click-through rates.
//        - consider the user’s thought process and why they will click on this page.
//        - include keywords where relevant and natural to do so.
//        - There are also situations where it can make sense not to create a meta description and 
//        - let the search engine pull content from the page to form a description that 
//        - more accurately maps to a user’s search.
//        - The Meta name Description is an HTML element that summarizes your web page.
//          Search engines typically show the Meta description in search results below your Title tag
//        - Add a “call-to-action” to your description tag (Get | Order | Receive | Read how | How to | Review | Now | Easy)
//        - Strategically provide solutions to a problem
//        - Use numbers to your description tags (5 Important HTML tags for your website to improve your SEO)
//        - Start your description tag with your main targeted keyword
//        - Don’t stuff your description tag with keywords
//        - Every page should have a unique description tag with the emphasis on one specific keyword
//   
//      SEO Page Content Suggestions:
//      - USE TAGS CORECTLY for SEO:
//      - H1/H2/H3/H4/H5/H6: Use only one h1/page, can use many h2, h3, etc... 
//        Helps search engine to break page into smaller chunks
//      - Highlights: Italics is best/ Bold is better /strong and em
//      - Use specific tags: abbr, cite and blockquote
//      - Always specify 'alt' for img tag.
//        <img src=”on-site-search-optimization-seo-title-tag.png” alt=”On-Site Search Optimization SEO Title Tag” />
//      - Cross-link: Link across web pages. ie.   Recall on meat > link to Recall on other meat products OR master page having links to all meat recalls 
//      
//      ==== NOTE #5 ====
//      og:xxxx properties
//   
//      <meta property="og:locale" content="en_US"/>
//      <meta property="og:type" content="website"/>
//      - website | article | vidoe.movie
//      <meta property="og:title" content="Search, list & view all recalls in one place · RecallsMe"/>
//      - Title used by social media platforms like twitter or FB - 40 chars
//      - The title should **NOT** contain advertising information, branding, the site name, etc
//      <meta property="og:site_name" content="RecallsMe"/>
//      <meta property="og:description" content="The one and only web site offering .... safety alerts"/>
//      - Desc used by social media platforms like twitter or FB - 200 chars
//      <meta property="og:url" content="https://recalls-me.docker.localhost/index"/>
//      - The canonical URL of the page.      
//      <meta property="og:image" content="https://recalls-me.docker.localhost/images/logo/sitePic.png"/>
//      - image displayed when listing the page on social media.
//        Ideally, it should be a square image with the important content 
//        placed in the middle of the square in a rectangle with a 2:1 ratio. 
//        This will make sure that the image will look good in cards with rectangle and square shaped images.
//        Rules for Twitter: Images for this Card support an aspect ratio of 2:1 with minimum dimensions of 
//        300x157 or maximum of 4096x4096 pixels. 
//        Images must be less than 5MB in size. JPG, PNG, WEBP and GIF formats are supported.
//      <meta property="og:image:secure_url" content="https://recalls-me.docker.localhost/images/logo/sitePic.png"/>
//      <meta property="og:image:alt" content="Picture of Logo"/>
//      <meta name="twitter:card" content="summary"/>
//      - This meta tag defines how cards will look when shared on Twitter. 
//        There are two options for websites, summary (square card about 300x300px) and 
//        summary_large_image(rectangle about 600x400px)
//      <meta name="twitter:site" content="@RecallChecks"/>
//      <meta name="twitter:creator" content="@RecallChecks"/>
//      <meta name="twitter:title" content="Recalls Alert Service · RecallsMe"/>
//      <meta name="twitter:description" content="Only RecallsMe offers a ..... they are recalled."/>
//   
//      <link rel="canonical" href="https://example.com/xxxxx" />
//      - designate page as the official page. list the full url. But don't designate any other similar page(s).
//      <link rel="icon"             type="image/png"                  href="/favicon/favicon.png?v=2"/>
//      <link rel="sitemap"          type="application/xml"            href="/sitmap/sitemap-index.xml"/>
//      <link rel="manifest"         type="application/manifest+json"  href="/manifest.webmanifest"/>
//      - For Android devices we need a .webmanifest file, which provides browsers with the information 
//        where the icons needed for the home screen and the splash screen for PWAs are located.
//      <link rel="apple-touch-icon" sizes="48x48"                     href="/icons/icon-48x48.png"/>
//      <link rel="apple-touch-icon" sizes="72x72"                     href="/icons/icon-72x72.png"/>
//      <link rel="apple-touch-icon" sizes="96x96"                     href="/icons/icon-96x96.png"/>
//      <link rel="apple-touch-icon" sizes="144x144"                   href="/icons/icon-144x144.png"/>
//      <link rel="apple-touch-icon" sizes="192x192"                   href="/icons/icon-192x192.png"/>
//      <link rel="apple-touch-icon" sizes="256x256"                   href="/icons/icon-256x256.png"/>
//      <link rel="apple-touch-icon" sizes="384x384"                   href="/icons/icon-384x384.png"/>
//      <link rel="apple-touch-icon" sizes="512x512"                   href="/icons/icon-512x512.png"/>
//   
//      ==== NOTE #6 ====
//      Types of Pages:
//      WebIndexPage  - Landing page
//      WebPage       - A web page
//      BlogIndexPage - Blog landing page
//      BlogPage      - A blog page
//      AppPage       - Dynamic page
//      MDXPage       - MDX page
//   
//      ==== NOTE #6 ====
//      List of Pages for RecallsMe (As of Mar, 2023):
//   
//      Do the vite build (npm run build) and use the output to capture urlList in a file, and then run:
//      cat urlList | cut -d'/' -f3-4 | grep html | cut -d'.' -f1 | sort -k1,5 
//      and then discard the select/xxxx, recall/xxxx  to get unique page list.
//      (Add prefix /)
//      (Add '/renderer/_error' which is a VITE generated page)
//   
//      /renderer/_error
//   
//      /404
//      /about
//      /blog
//      /blog/blog1
//      /blog/blog2
//      /blog/blog3
//      /blog/blog4
//      /blog/blogtemplate
//      /blog/howto
//      /blog/techblogtemplate
//      /credits
//      /disclaimers
//      /faq
//      /getstarted
//      /howto
//      /HowToList
//      /HowToPwa
//      /HowToRegProd
//      /HowToRegVehi
//      /HowToSearch
//      /HowToSelCat
//      /HowToSetDel
//      /index
//      /learn
//      /myrecalls
//      /privacy
//      /myaccount
//      /recall
//      /recall/04-00106010-apheresis-platelets-leukocytes-reduced
//      /recent
//      /sampleMdx01
//      /sampleMdx02
//      /sampleMdx03
//      /sampleMdx06
//      /sampleMdxSyntax
//      /select
//      /select/03-00101172-fitbit-recalls-ionic-smartwatches-due-to-burn-hazard-one-million-sold-in-the-u
//      /services
//      /termsandconditions
//   

// INPUT:
//      pagePath: above listed page url (/index)
//      '',   //frontmatter: { meta: {
//         file: 'blog2',
//         pin: false,
//         draft: false,
//         title: 'Blog Post 2',
//         personSeoCode: 'principal',
//         datePub: '2020-02-12',
//         dateMod: '2020-02-12',
//         pic: '/images/mdxpages/chef-boyardee.jpg',
//         excerpt: 'Blog Description',
//         tags: [Array],
//         readTime: 2
//       }
//      }
//      staticDataLoadResp: { data: { url:, emert:, emerts:, } }
//      slug: 

